<template>
  <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
    <div class="del-img" v-for="(item) in imgList" :key="item.Id" @click.stop="see(item)" style="position: relative">
      <p class="del-icon" @click.stop="deletefile(item.Id)" v-if="Type ==1">x</p>
      <img src="../../assets/image/mor.png">
      <el-tooltip placement="top">
        <div slot="content">{{item.FileName}}.{{item.Suffix}}</div>
        <span>{{item.FileName}}.{{item.Suffix}}</span>
      </el-tooltip>
      <i class="el-icon-view p-hover" style="position: absolute;bottom:48px;left:12px;color:#333;z-index: 9900" @click.stop="see(item)"></i>
      <a style="position: absolute;top: -36px;left:82px;color: #333;z-index: 99990" @click.stop="downloadfile(item.Id)" class="p-hover">
        <span class="el-icon-download" style="font-size: 16px;margin-left: 10px"></span>
      </a>
    </div>
    <div class="deleteAppropriationprogressdialog">
      <el-dialog
        :append-to-body="true"
        width="450px"
        :visible.sync="filedeletedialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
          <span style=" display: inline-block;width: 26px;line-height: 26px;
      border-radius: 50%;background: #ffba00!important;text-align: center;color: #fff;margin-right: 10px;vertical-align: middle;">!</span>
          <span style="font-size: 18px ;display: inline-block;vertical-align: middle;color: #666666;">此操作将永久删除文件,是否继续？</span>
        </div>
        <div class="btn">
          <el-button @click="filedeletedialog=false">取消</el-button>
          <el-button @click="okdelete">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import reseacrh from "@/api/research";
import {downloadFileId} from "@/utils/downloadUrl";

export default {
  data() {
    return {
      imgList: [],
      delFileId: "",
      filedeletedialog: false
    }
  },
  props: ["DataGuid","Type"],
  created() {
    if (this.DataGuid) {
      this.deleitImgList()
    }
  },
  methods: {
    see(item) {
      reseacrh.preview(item.Id).then(res=>{
        if(res.data.Status==1){
          window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
        }
      })
      console.log('查看图片',item)
    },
    deleitImgList() {
      reseacrh.preFinancFile(this.DataGuid).then(res=>{
        console.log(res);
        if(res.data.Status==1){
          this.imgList=res.data.ExtraData
        }else{
          alert(res.data.Message)
        }
      })
    },
    deletefile(id){//删除文件弹框
      this.delFileId = id
      this.filedeletedialog = true
    },
    okdelete(){//确定删除
      let params = {
        Id:this.delFileId,
      };
      reseacrh.alldelet(params).then(res=>{
        console.log(res);
        if(res.data.Status==1){
          this.$message({
            message: '删除文件成功',
            type: 'success'
          });
          this.deleitImgList()
          this.filedeletedialog = false
        }else{
          alert(res.data.Message)
        }
      })
    },
    downloadfile(id){
      downloadFileId(id)
    },
  }
}

</script>

<style scoped>
.p-hover:hover {
  cursor:pointer
}
</style>
