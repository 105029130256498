import reseacrh from "@/api/research";
import Message from "element-ui/packages/message/src/main";

export const downloadA = (src, name) => {
    console.log('downloadA执行了');
    var request = new XMLHttpRequest();
    request.responseType = "blob";
    request.open("GET", src);
    request.onload = function () {
        var url = window.URL.createObjectURL(this.response);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = name;
        a.click()
        // a.remove()
    }
    request.send();
    // window.open(url)
}

export const downloadFileId = (id, preview = false) => {
    console.log('downloadFileId执行了');
    reseacrh.filepermissions(id).then(respose => {
        if (respose.data.Status == 1) {
            reseacrh.filedownload({ Id: id }).then(res => {
                // let data = res.data;
                // let filename = decodeURIComponent(res.headers.filename)
                // downLoadXls(data,filename)
                let fileName = res.headers.filename
                if (res.status == 200) {
                    let data = res.data;
                    let filename = decodeURIComponent(res.headers.filename)
                    if (preview) {
                        console.log('预览', data);
                        // 预览
                        previewFn(data, fileName)
                    } else {
                        // 下载
                        downLoadXls(data, filename)
                    }
                } else {
                    Message.error("文件不存在")
                }
            })
        } else {
            Message.error(respose.data.Message)
        }
    })
}

export const NoticeFileId = (DataId, MessageId) => {
    console.log('NoticeFileId执行了');
    var obj = {
        DataId: DataId,
        MessageId: MessageId
    }
    reseacrh.noticeFileDownload(obj).then(respose => {
        if (respose.data.Status == 1) {
            reseacrh.noticedownload(obj).then(res => {
                // let data = res.data;
                // let filename = decodeURIComponent(res.headers.filename)
                // downLoadXls(data,filename)
                if (res.status == 200) {
                    let data = res.data;
                    let filename = decodeURIComponent(res.headers.filename)
                    downLoadXls(data, filename)
                } else {
                    Message.error("文件不存在")
                }
            })
        } else {
            Message.error(respose.data.Message)
        }
    })
}
export function downLoadXls(data, filename) {
    console.log(data);
    // var blob = new Blob([data], { type: "application/vnd.ms-excel" }); //接收的是blob，若接收的是文件流，需要转化一下
    console.log('downLoadXls调用了文件名是', filename);
    if (typeof window.chrome !== "undefined") {
        // Chrome version
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(data);
        link.download = filename || '';
        link.click();
    } else if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE version
        var blob = new Blob([data], { type: "application/force-download" });
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // Firefox version
        var file = new File([data], filename, {
            type: "application/force-download",
        });
        window.open(URL.createObjectURL(file));
    }
}

export function previewFn(data, fileName) {

    let blob = new Blob([data], { type: 'application/pdf;charset=UTF-8' });
    var href = window.URL.createObjectURL(blob); //创建下载的链接
    console.log(href);

    let index = fileName.lastIndexOf('.')
    let fileType = fileName.substring(index + 1)
    console.log(fileType);
    if (fileType == "pdf" || fileType == "PDF") {
        let win = window.open("/pdfjs/web/viewer.html?file=" + href)
        const loop = setInterval(function () {
            if (win.closed) {
                clearInterval(loop);
            } else {
                win.document.title = fileName;
            }
        }, 1000);
    } else if (fileType == "jpg" || fileType == "png" || fileType == "jpeg" || fileType == "PNG" || fileType == "bmp") {
        let win = window.open("/pdfjs/web/img.html?file=" + href)
        const loop = setInterval(function () {
            if (win.closed) {
                clearInterval(loop);
            } else {
                win.document.title = fileName;
            }
        }, 500);
    } else {
        Message.warning("暂时只支持pdf文件预览,请下载查看")
    }
}
