import axios from "./Interceptor.js"
import qs from "qs"
let dizi1 =window.global_config.BASE_URL
let dizi2 =window.global_config.BASE_URL2
let research={
    topic:(id)=>{//单个课题
        return axios({
            method:"get",
            url:`${dizi1}TopicInfo/Get?Id=${id}`,
        })
     },
    Subjecttype:(type)=>{////获取课题类型列表和获取课题方式列表和获取课题计划说明列表和阶段所属计划的名称
        return axios({
            method:"get",
            url:`${dizi1}BaseData/GetBaseDataByType?type=${type}`,
        })
     },
     xiangmu:()=>{//项目课题类型
        return axios({
            method:"get",
            url:`${dizi1}TopicType/GetAll`,
        })
     },
     allmember:(id)=>{//获取当前登录用户的组织下所有人员
        return axios({
            method:"get",
            url:`${dizi1}Account/GetHospitalUser?userId=${id}`,
        })
     },
     allrole:()=>{//所有角色身份
         return axios({
             method:"get",
             url:`${dizi1}Role/GetAll`
         })
     },
     allmenu:()=>{//菜单
        return axios({
            method:"get",
            url:`${dizi1}TopicMenu/GetAll`,
        })
     },
    addTopic:(info)=>{//新增帖子
        return axios({
            method:"post",
            url:`${dizi1}TopicBBS/Insert`,
            data:info
        })
    },
     allTopic:(info)=>{//帖子列表
        return axios({
            method:"post",
            url:`${dizi1}TopicBBS/GetPaged`,
            data:qs.stringify(info)
        })
    },
     Newstage:(info)=>{//新增阶段
        return axios({
            method:"post",
            url:`${dizi1}TopicFlow/Insert`,
            data:qs.stringify(info)
        })
    },
    deletestage:(id)=>{//删除阶段
        return axios({
            method:"post",
            url:`${dizi1}TopicFlow/Delete`,
            data:id
        })
    },
    modifystage:(info)=>{//修改阶段
        return axios({
            method:"post",
            url:`${dizi1}TopicFlow/Update`,
            data:qs.stringify(info)
        })
    },
    conversion:(info)=>{//阶段位置转换
        return axios({
            method:"get",
            url:`${dizi1}TopicFlow/OrderExchange`,
            params:info
        })
    },
    deletecatalog:(id)=>{//删除目录
        return axios({
            method:"post",
            url:`${dizi1}TopicFlowFolder/Delete`,
            data:qs.stringify(id)
        })
    },
    statelist:(id)=>{//查询当前阶段状态
        return axios({
            method:"post",
            url:`${dizi1}TopicFlow/GetFlowState`,
            data:qs.stringify(id)
        })
    },
    stage:(id)=>{//阶段提交审核
        return axios({
            method:"post",
            url:`${dizi1}TopicFlow/SubmitCheck`,
            data:qs.stringify(id)
        })
    },
    allstage:(info)=>{//阶段下的审核通过或驳回
        return axios({
            method:"post",
            url:`${dizi1}TopicFlow/FlowCheck`,
            data:info
        })
    },
    planUpload:(info)=>{//上传计划的资料
        return axios({
            method:"post",
            url:`${dizi1}TopicFlowPlan/UploadFile`,
            data:info
        })
    },
    filedownload:(id)=>{//目录和计划文件下载
        return axios({
            method:"post",
            url:`${dizi1}TopicData/DownloadDataFile`,
            data:qs.stringify(id),
            responseType:'blob',
        })
    },
    noticedownload:(info)=>{//目录和计划文件下载
        return axios({
            method:"post",
            url:`${dizi1}TopicMessage/DownloadDataFile`,
            data:qs.stringify(info),
            responseType:'blob',
        })
    },
    locking:(info)=>{//锁定课题
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/TopicInfoLock`,
            data:qs.stringify(info),
        })
    },
    tilemodify:(info)=>{//阶段下计划文件驳回和审核完成
        return axios({
            method:"post",
            url:`${dizi1}TopicFlowPlan/DataCheck`,
            data:info,
        })
    },
    alldelet:(info)=>{//目录文件的删除
        return axios({
            method:"post",
            url:`${dizi1}TopicData/Delete`,
            data:qs.stringify(info),
        })
    },
    addPosts:(info)=>{//科研创建帖子
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/InsertBBS`,
            data:qs.stringify(info)
        })
    },
    listPosts:(info)=>{//科研帖子信息列表
        return axios({
            method:"post",
            url:`${dizi1}TopicBBS/GetPaged`,
            data:qs.stringify(info)
        })
    },
    editPosts:(info)=>{//科研帖子编辑
        return axios({
            method:"post",
            url:`${dizi1}TopicBBS/Update`,
            data:qs.stringify(info)
        })
    },
    deletePosts:(id)=>{//科研帖子的删除
        return axios({
            method:"post",
            url:`${dizi1}TopicBBS/Delete`,
            data:qs.stringify(id)
        })
    },
    numPosts:(id)=>{//科研帖子的每个帖子信息
        return axios({
            method:"get",
            url:`${dizi1}TopicBBS/Get?Id=${id}`,
        })
    },
    replyPosts:(info)=>{//科研新增回复帖子
        return axios({
            method:"post",
            url:`${dizi1}TopicBBSList/Insert`,
            data:qs.stringify(info)
        })
    },
    postPosts:(info)=>{//科研回复帖子数据分页
        return axios({
            method:"post",
            url:`${dizi1}TopicBBSList/GetPaged`,
            data:qs.stringify(info)
        })
    },
    UpdatePosts:(info)=>{//科研修改回复帖子
        return axios({
            method:"post",
            url:`${dizi1}TopicBBSList/Update`,
            data:qs.stringify(info)
        })
    },
    DeletePosts:(info)=>{//科研删除回复帖子帖子
        return axios({
            method:"post",
            url:`${dizi1}TopicBBSList/Delete`,
            data:qs.stringify(info)
        })
    },
    Stages:(info)=>{//查询操作日志列表分页
        return axios({
            method:"post",
            url:`${dizi1}TopicOperationLog/GetPaged`,
            data:qs.stringify(info)
        })
    },
    nummsg:()=>{//消息提醒功能,查询消息提醒数量。
        return axios({
            method:"get",
            url:`${dizi1}TopicMessage/GetNew`,
        })
    },
    querymsg:(info)=>{//查询消息列表分页
        return axios({
            method:"post",
            url:`${dizi1}TopicMessage/GetPaged`,
            data:qs.stringify(info)
        })
    },
    getmsg:(id)=>{//查看消息提醒功能
        return axios({
            method:"get",
            url:`${dizi1}TopicMessage/Get?Id=${id}`,
        })
    },
    allmsg:(info)=>{//批量发送消息通知
        return axios({
            method:"post",
            url:`${dizi1}TopicMessage/Inserts`,
            data:info
        })
    },
    sendoutmsg:(info)=>{//查看批量发送消息通知数据
        return axios({
            method:"post",
            url:`${dizi1}TopicMessage/GetPagedSend`,
            data:qs.stringify(info)
        })
    },
    seebudget:(id)=>{//查看预算
        return axios({
            method:"get",
            url:`${dizi1}TopicInfo/GetTopicBudget?TopicId=${id}`,
        })
    },
    addtopic:(info)=>{//新增计划说明，课题方式
        return axios({
            method:"post",
            url:`${dizi1}BaseData/Insert`,
            data:qs.stringify(info)
        })
    },
    addtype:(info)=>{//新增课题类型
        return axios({
            method:"post",
            url:`${dizi1}TopicType/Insert`,
            data:qs.stringify(info)
        })
    },
    updatetopic:(info)=>{//修改课题类型，计划说明，课题方式
        return axios({
            method:"post",
            url:`${dizi1}BaseData/Update`,
            data:qs.stringify(info)
        })
    },
    updatetype:(info)=>{//修改课题类型
        return axios({
            method:"post",
            url:`${dizi1}TopicType/Update`,
            data:qs.stringify(info)
        })
    },
    menulists:(MenuType)=>{//根据type显示菜单
        return axios({
            method:"get",
            url:`${dizi1}TopicMenu/GetAllType?MenuType=${MenuType}`,
        })
    },
    addmenu:(info)=>{//新增菜单
        return axios({
            method:"post",
            url:`${dizi1}TopicMenu/Insert`,
            data:qs.stringify(info)
        })
    },
    Deletemenu:(id)=>{//菜单的删除
        return axios({
            method:"post",
            url:`${dizi1}TopicMenu/Delete`,
            data:qs.stringify(id)
        })
    },
    Updatemenu:(info)=>{//菜单的修改
        return axios({
            method:"post",
            url:`${dizi1}TopicMenu/Update`,
            data:qs.stringify(info)
        })
    },
    basicstagelist:()=>{//基础阶段列表
        return axios({
            method:"get",
            url:`${dizi1}TopicBaseFlow/GetAll`,
        })
    },
    Updatebasicstagelist:(info)=>{//修改基础阶段信息
        return axios({
            method:"post",
            url:`${dizi1}TopicBaseFlow/Update`,
            data:qs.stringify(info)
        })
    },
    addbasicstagelist:(info)=>{//新增基础阶段
        return axios({
            method:"post",
            url:`${dizi1}TopicBaseFlow/Insert`,
            data:qs.stringify(info)
        })
    },
    Deletebasicstagelist:(id)=>{//删除基础阶段
        return axios({
            method:"post",
            url:`${dizi1}TopicBaseFlow/Delete`,
            data:qs.stringify(id)
        })
    },
    exchangebasicstagelist:(info)=>{//基础阶段变换顺序
        return axios({
            method:"get",
            url:`${dizi1}TopicBaseFlow/OrderExchange`,
            params:info
        })
    },
    jurisdiction:(TopicId)=>{//TopicId可以为0，为0时查出所有用户,课题下的人员
        return axios({
            method:"get",
            url:`${dizi1}TopicUser/GetAll?TopicId=${TopicId}`,
        })
    },
    adduser:(info)=>{//新增用户权限
        return axios({
            method:"post",
            url:`${dizi1}TopicUser/Insert`,
            data:qs.stringify(info)
        })
    },
    deleteuser:(id)=>{//删除用户权限
        return axios({
            method:"post",
            url:`${dizi1}TopicUser/Delete`,
            data:qs.stringify(id)
        })
    },
    withdraw:(id)=>{//撤回单人消息通知
        return axios({
            method:"post",
            url:`${dizi1}TopicMessage/Revocation`,
            data:qs.stringify(id)
        })
    },
    filelist:(id)=>{//文件显示
        return axios({
            method:"post",
            url:`${dizi1}TopicData/GetPaged`,
            data:qs.stringify(id)
        })
    },
    filepermissions:(id)=>{//询问文件下载权限
        return axios({
            method:"get",
            url:`${dizi1}TopicData/DownLoadDataFileCheck?Id=${id}`,
        })
    },
    personnelupdate:(info)=>{//人员的编辑
        return axios({
            method:"post",
            url:`${dizi1}TopicUser/Update`,
            data:qs.stringify(info)
        })
    },
    GetTopicall:(id)=>{//显示项目资料
        return axios({
            method:"get",
            url:`${dizi1}TopicFlow/GetTopicFlowDataCollectView?TopicId=${id}`,
        })
    },
    sontopic:(id)=>{//查询子课题列表无分页
        return axios({
            method:"get",
            url:`${dizi1}TopicInfo/GetChildAll?Id=${id}`,
        })
    },
    hospitallist:(key)=>{//查询医院列表
        return axios({
            method:"post",
            url:`${dizi1}Hospital/GetAll`,
            data:key
        })
    },
    hospitalIds:(hospitalId)=>{//通过医院ID查询用户ID
        return axios({
            method:"get",
            url:`${dizi1}Account/GetHospitalUsers?hospitalId=${hospitalId}`,
        })
    },
    UploadFileBudget:(info)=>{//项目财务管理上传文件
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/UploadFileBudget`,
            data:info
        })
    },
    GetPagedBudget:(info)=>{//财务凭证列表
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/GetPagedBudget`,
            data:qs.stringify(info)
        })
    },
    lawsfile:(info)=>{//法律上传文件
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/UploadFileLaw`,
            data:info
        })
    },
    lawslist:(info)=>{//法律列表
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/GetPagedLaw`,
            data:qs.stringify(info)
        })
    },
    listton:(ProjectId)=>{
        return axios({
            method:"get",
            url:`${dizi1}TopicInfoStatistics/GetTopicInfoProjectStatistics?ProjectId=${ProjectId}`,
        })
    },
    arrton:(ProjectId)=>{
        return axios({
            method:"get",
            url:`${dizi1}TopicInfoStatistics/GetTopicProjectNum?ProjectId=${ProjectId}`,
        })
    },
    preview:(id)=>{
        return axios({
            method:"get",
            url:`${dizi1}TopicData/Get?Id=${id}`
        })
    },
    Deletetype:(info)=>{//删除课题类型
        return axios({
            method:"post",
            url:`${dizi1}TopicType/Delete`,
            data:qs.stringify(info)
        })
    },
    dataGuid:(info)=>{//全部撤回
        return axios({
            method:"post",
            url:`${dizi1}TopicMessage/RevocationAll`,
            data:info
        })
    },
    uptop:(info)=>{//修改项目
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/Update`,
            data:info
        })
    },
    Completed:(info)=>{//修改计划为已完成或者未完成
        return axios({
            method:"post",
            url:`${dizi1}TopicFlowPlan/PlanPerform`,
            data:info
        })
    },
    editPwd:(info) =>{ // 修改密码
        return axios({
            method:"post",
            url:`${dizi1}Account/UpdatePassword`,
            data:qs.stringify(info)
        })
    },
    FinancInfo: () => { // 凭证信息
        return axios({
            method:"get",
            url:`${dizi1}TopicBaseAccount/GetAll`
        })
    },
    FinancList:(info) =>{ //凭证列表分页
        return axios({
            method:"post",
            url:`${dizi1}TopicFinanc/GetPaged`,
            data:info
            // params: info
        })
    },
    FinancImgList:(info) =>{ //凭证图片列表分页
        return axios({
            method:"post",
            url:`${dizi1}TopicFinanc/GetpicturePaged`,
            data:info
        })
    },
    AddFinanc:(info) =>{ //新增凭证
        return axios({
            method:"post",
            url:`${dizi1}TopicFinanc/Insert`,
            data:info
        })
    },
    editFinanc:(info) =>{ //修改凭证
        return axios({
            method:"post",
            url:`${dizi1}TopicFinanc/Update`,
            data:info
        })
    },
    delFinanc:(info) =>{ //删除凭证
        return axios({
            method:"post",
            url:`${dizi1}TopicFinanc/delete`,
            data:info
        })
    },
   FinancFileList:(info) =>{ //凭证文件列表分页
        return axios({
            method:"post",
            url:`${dizi1}TopicFinancFile/GetPaged`,
            data:qs.stringify(info)
        })
    },
    addFinancFile:(info) =>{ //新增凭证文件
        return axios({
            method:"post",
            url:`${dizi1}TopicFinancFile/Insert`,
            data:info
        })
    },
    updateFinancFile: (info) => { // 更新凭证文件
        return axios({
            method:"post",
            url:`${dizi1}TopicFinancFile/Update`,
            data:info
        })
    },
    preFinancFile: (DataGuid) => { // 显示文件列表
        return axios({
            method:"get",
            url:`${dizi1}TopicData/GetAll?DataGuid=${DataGuid}`,
        })
    },
    delFinancFile: (info) => { // 删除相关文件
        return axios({
            method:"post",
            url:`${dizi1}TopicFinancFile/delete`,
            data: info
        })
    },
    TopicUploadFile: (info) => { //通用项目文件上传功能
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/TopicUploadFile`,
            data: info
        })
    },
    GetPagedTopicDataType: (info) => { //分页查询文件列表
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/GetPagedTopicDataType`,
            data: info
        })
    },
    GetPagedTopicDataTypeData: (info) => { //分页查询原始数据文件列表
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/GetPagedTopicDataTypeData`,
            data: info
        })
    },
    TopicDataUpdateDataType: (info) => { // 更新原始数据
        return axios({
            method:"post",
            url:`${dizi1}TopicInfo/UpdateDataType`,
            data: info
        })
    },
    TypeDataInfo: (TypeId) => { //分页查询原始数据文件对象
        return axios({
            method:"get",
            url:`${dizi1}TopicInfo/GetDataType?Id=${TypeId}`,
        })
    },
    settingUserDel: (info) => { // 成员删除
        return axios({
            method:"post",
            url:`${dizi1}Account/Delete`,
            data: info
        })
    },
    DownloadFinancFile: (info) => { //下载凭证图片集合 打包
        return axios({
            method:"post",
            // url:`${dizi1}TopicFinanc/DownloadFinancFile`,
            url:`${dizi1}TopicFinanc/GetFileZip`,
            data: qs.stringify(info),
            responseType:'blob',
        })
    },
    FinancZFileZip: (info) => { //凭证文件打包
        return axios({
            method:"post",
            url:`${dizi1}TopicFinancFile/GetFileZip`,
            data: qs.stringify(info),
            responseType:'blob',
        })
    },
    DownloadFileZip: (info) => { //通用文件打包下在功能
        return axios({
            method:"post",
            url:`${dizi1}TopicData/GetFileZip`,
            data: qs.stringify(info),
            responseType:'blob',
        })
    },
    getUserSList: (info) => { // 获取项目成员列表
        return axios({
            method: "post",
            url: `${dizi1}TopicUser/GetPaged`,
            data: info
        })
    },
    getUsersInfo : (Id) => {  // 获取成员信息
        return axios({
            method: "get",
            url: `${dizi1}TopicUser/Get?Id=${Id}`,
        })
    },
    personnelupData: (info) => {//项目人员的编辑
        return axios({
            method: "post",
            url: `${dizi1}Account/Update`,
            data: qs.stringify(info)
        })
    },
    sendMessage : (info) => { // 通知通告完成之后发送短信
        return axios({
            method: "post",
            url: `${dizi2}`,
            data: info
        })
    },
    addprojectUser: (info) => {//项目新增成员
        return axios({
            method: "post",
            url: `${dizi1}TopicUser/InsertProjectUser`,
            data: info
        })
    },
    editProjectFile: (info) => {
        return axios({
            method: "post",
            url: `${dizi1}TopicData/Insert`,
            data: info
        })
    },
    listRole:(data)=>{
        return axios({
            method:"get",
            url:`${dizi1}TopicUser/GetPurchaseUserRole`,
            params: data
        })
    },
    updateRole: (info) => {//更新系统设置的角色
        return axios({
            method: "post",
            url: `${dizi1}TopicUser/Update`,
            data: qs.stringify(info)
        })
    },
    noticeFileDownload:(info)=>{// 消息通知下载权限
        return axios({
            method:"post",
            url:`${dizi1}TopicMessage/DownLoadDataFileCheck`,
            data:qs.stringify(info)
        })
    },
}
export  default research
