<template>
<div class="loading_frame" v-show="show==true">
  <!-- <div class="quan">
      <div class="shui"></div>
    </div> -->
       <img src="../assets/image/jz.gif">
        <p>数据加载中，请稍候…</p>
</div>
</template>
<script>
export default {
    data(){
        return{
            show:false,
        }
    },
	computed: {
	},
    created(){
        setTimeout(()=>{
            this.show=true
        },2000)
    },
    mounted(){
    }

};
</script>
<style lang="scss" scoped>
.loading_frame {
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
    img{
        width: 100px;
        height: 100px;
    }
//   .quan{
//             position: relative;
//             width: 180px;
//             height: 180px;
//             border-radius: 50%;
//             border: 3px solid rgb(246, 247, 248);
//             box-shadow: 0 0 0 3px rgb(41, 134, 196);

//         }
//         .shui{
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             background-color: rgb(23, 106, 201);
//             border-radius: 50%;
//             overflow: hidden;
//         }
//         .shui::after{
//             content: '';
//             position: absolute;
//             top: 0;
//             left: 50%;
//             width: 150%;
//             height: 150%;
//             border-radius: 40%;
//             background-color: rgb(240, 228, 228);
//             animation: shi 5s linear infinite;

//         }
//         @keyframes shi{
//             0%{
//                 transform: translate(-50%,-65%) rotate(0deg);

//             }
//             100%{
//                 transform: translate(-50%,-65%) rotate(360deg);

//             }
//         }
//         .shui::before{
//             content: '';
//             position: absolute;
//             top: 0;
//             left: 50%;
//             width: 150%;
//             height: 150%;
//             border-radius: 42%;
//             background-color: #fff;
//             animation: xu 7s linear infinite;

//         }
//         @keyframes xu{
//             0%{
//                 transform: translate(-50%,-60%) rotate(0deg);

//             }
//             100%{
//                 transform: translate(-50%,-60%) rotate(360deg);

//             }
//         }
    }
</style>
