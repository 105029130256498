import axios from "axios";
import store from "../store/index"
import Message from "element-ui/packages/message/src/main";
axios.defaults.timeout = 3000000
axios.interceptors.request.use(config => {
    if (window.sessionStorage.getItem("Token")) {
        config.headers["accessToken"] = window.sessionStorage.getItem("Token")
        store.commit('showLoading')
    }
    return config;
}, error => {
    // if(error.message.includes('timeout')){
    //     alert("当前网络不稳定，请稍后在试!")
    //     store.commit('hideLoading')
    // }
    store.commit('hideLoading')
    return Promise.reject(error);
});
axios.interceptors.response.use(response => {

    store.commit('hideLoading')
    if (response.status == 204 && response.config.responseType == 'blob') {
       Message.error("文件不存在")
    }
    return response;
}, error => {
    if (error.response) {
        let url=window.global_config.BASE_URL + "TopicFinanc/GetFileZip"
        let url1=window.global_config.BASE_URL + "TopicFinancFile/GetFileZip"
        switch (error.response.status) {
            case 401:
                store.commit('hideLoading')
                alert("401接口调用错误")
                break;
            case 500:
                if (error.response.config.url == url) {
                    store.commit('hideLoading')
                    Message.error("打包的文件为空")
                    return false
                }
                store.commit('hideLoading')
                alert("500接口调用错误")
                break;
            case 400:
                if (error.response.config.url == url1) {
                    store.commit('hideLoading')
                    Message.error("打包的文件为空")
                    return false
                }
                store.commit('hideLoading')
                alert("400接口调用错误")
                break;
            case 404:
                store.commit('hideLoading')
                alert("404接口调用错误")
                break;
        }
        return Promise.reject(error.response.data);
    }
    if (error && error.stack.indexOf('timeout') > -1) {
        store.commit('hideLoading')
        alert('现在网络不稳定,请稍后在试!')
    }
});

export default axios;
