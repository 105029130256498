import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    b: false,
    menuindex:1,
    projectId: '' || window.sessionStorage.getItem('projectId') ,//储存项目id
    treeId: '' || window.sessionStorage.getItem('treeId'), //储存树项目id
    DataGuid: sessionStorage.getItem('DataGuid') || '', //储存课题guid
    LOADING: false,
    nodekey:"",//储存树节点key
    Key: "" || window.sessionStorage.getItem('key'), // 保存key
    pmid:"",//储存项目管理里面的列表
    noticeList: false,
    disabledflag:false || window.sessionStorage.getItem('disabledflag') ,//储存控制档案管理里面显示内容权限
  },
  mutations: {
    midshow(state,disabledflag){
      sessionStorage.setItem("disabledflag",disabledflag)
      state.disabledflag=disabledflag
    },
    pmiddata(state,pmid){
      sessionStorage.setItem("pmid",pmid)
      state.pmid=pmid
    },
    nodeKey(state,Key) {
      sessionStorage.setItem("key",Key)
      state.Key=Key
    },
    nodekeydata(state,key){
      sessionStorage.setItem("nodekey",key)
      state.nodekey=key
    },
    showflag(state, val) {
      state.b = val
    },
    notice(state,val) {
      state.noticeList=val
    },
    menustate(state,val){//控制菜单高亮
      sessionStorage.setItem("menuindex",val)
      state.menuindex=val
    },
    SaveId(state, val){//储存项目id
      sessionStorage.setItem("projectId",val)
      state.projectId=val
    },
    treedata(state, val) { //储存树项目id
      sessionStorage.setItem("treeId",val)
      state.treeId=val
    },
    Guid(state,guid){//储存课题guid
      sessionStorage.setItem("DataGuid",guid)
      state.DataGuid=guid
    },
    showLoading(state) {
			state.LOADING = true;
		},
		hideLoading(state) {
			state.LOADING = false;
		},
  },
  actions: {},
  modules: {},
});
