import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
      path: "/VerificationCode",//验证码
      name: "VerificationCode",
      component: ()=> import('../views/VerificationCode.vue'),
    },
    {
      path: "/",//登录页
      name: "login",
      component: ()=> import('../views/login.vue'),
    },
    {
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/index')
    },
    {//科研数据首页
      path: "/HomePage",
      name: "HomePage",
      component: ()=> import('../views/HomePage.vue'),
    },
   {//测试
          path: "/Allstatistics",
          name: "Allstatistics",
          component: ()=> import('../views/Allstatistics.vue'),
    },
    {
      path: "/financeList", // 凭证弹框
      name: "FinanceList",
      component: ()=> import('../components/FinanceLIst.vue')
    },
    {//测试
          path: "/cheshi",
          name: "cheshi",
          component: ()=> import('../views/cheshi.vue'),
     },
    {
      path: "/ProjectManagementPage",//全部项目管理
      name: "ProjectManagementPage",
      component: ()=> import('../views/ProjectManagementPage.vue')
    },
    {
      path: "/MenuCommon",//菜单公用页
      name: "MenuCommon",
      component: ()=> import('../views/MenuCommon.vue'),
      children:[
        {
          path: "/ProjectManagement",//项目管理
          name: "ProjectManagement",
          redirect:'/StatisticalOverview',
          component: ()=> import('../views/menu/ProjectManagement.vue'),
          children:[
            {
              path: "/StatisticalOverview",//项目管理下的统计概览
              name: "StatisticalOverview",
              component: ()=> import('../views/menu/projectmanagement/StatisticalOverview.vue')
            },
            {
              path: "/ProjectList",//项目管理下的项目列表
              name: "ProjectList",
              component: ()=> import('../views/menu/projectmanagement/ProjectList.vue')
            },
            {
              path: "/LawsAndRegulations",//项目管理下的法律法规
              name: "LawsAndRegulations",
              component: ()=> import('../views/menu/projectmanagement/LawsAndRegulations.vue')
            },
            {
              path: "/NoticeNotice",//项目管理下的通知通告
              name: "NoticeNotice",
              component: ()=> import('../views/menu/projectmanagement/NoticeNotice.vue')
            },
            {
              path: "/TrainingJi",//项目管理下的培训交流
              name: "TrainingJi",
              component: ()=> import('../views/menu/projectmanagement/TrainingJi.vue')
            },
            {
              path: "/DiscussionArea",//项目管理下的人才交流
              name: "DiscussionArea",
              component: ()=> import('../views/menu/projectmanagement/DiscussionArea.vue'),
            },
            {
              path: "/personnel",//项目管理下的新增的菜单
              name: "personnel",
              component: ()=> import('../views/menu/projectmanagement/personnel.vue'),
            },
            {
              path: "/Feedback",//项目管理下的问题反馈
              name: "Feedback",
              component: ()=> import('../views/menu/projectmanagement/ProblemFeedback.vue'),
            },
            {
              path: "/Lifesaving",//项目管理下的问题反馈
              name: "Lifesaving",
              component: ()=> import('../views/menu/projectmanagement/Lifesaving.vue'),
            }
          ]
        },
        {
          path: "/TaskManagement",//任务管理
          name: "TaskManagement",
          redirect:'/ProjectInformation',
          component: ()=> import('../views/menu/TaskManagement.vue'),
          children:[
            {
              path: "/ProjectInformation",//任务管理下的项目信息
              name: "ProjectInformation",
              component: ()=> import('../views/menu/taskmanagement/ProjectInformation.vue')
            },
            {
              path: "/Participants",//任务管理下的参研人员
              name: "Participants",
              component: ()=> import('../views/menu/taskmanagement/Participants.vue')
            },
            {
              path: "/ScientificResearchProcess",//任务管理下的科研过程
              name: "ScientificResearchProcess",
              component: ()=> import('../views/menu/taskmanagement/ScientificResearchProcess.vue')
            },
            {
              path: "/milepost",//任务管理下的里程碑
              name: "milepost",
              component: ()=> import('../views/menu/taskmanagement/milepost.vue')
            },
          ]
        },
        {
          path: "/FinancialManagement",//财务管理
          name: "FinancialManagement",
          redirect:'/ChartOfAccounts',
          component: ()=> import('../views/menu/FinancialManagement.vue'),
          children:[
            {
              path: "/ChartOfAccounts",//财务管理下的科目表
              name: "ChartOfAccounts",
              component: ()=> import('../views/menu/financialmanagement/ChartOfAccounts.vue')
            },
            {
              path: "/Finance",//财务管理下的财务资料
              redirect: '/Finance/FinanceZ',
              component: () => import('../views/menu/financialmanagement/Finance'),//财务资料
              children: [
                {
                  path: "FinanceZ",//财务管理下的财务资料
                  name: "FinanceZ",
                  component: ()=> import('../views/menu/financialmanagement/FinanceZ.vue') // 相关文件
                },
                {
                  path: "FinanceVoucher",//财务管理下的财务凭证
                  name: "FinanceVoucher",
                  component: ()=> import('../views/menu/financialmanagement/FinanceVoucher.vue') //财务凭证
                },
              ]
            },
            {
              path: "/Appropriationprogress",//财务管理下的拨款进度
              name: "Appropriationprogress",
              component: ()=> import('../views/menu/financialmanagement/Appropriationprogress.vue')
            },
          ]
        },
        {
          path: "/Archives", //档案管理
          name: "Archives",
          redirect: '/ArchivesFinance',
          component: () => import('../views/menu/ArchivesManagement.vue'),//财务资料
          children: [
            {
              path: "/ArchivesFinance", //财务档案
              name: "ArchivesFinance",
              redirect: '/ArchivesFinance/FinanceZ',
              component: () => import('../views/menu/financialmanagement/Finance.vue'),
              children: [
                {
                  path: "FinanceZ",//财务管理下的财务资料
                  // name: "FinanceZ",
                  component: ()=> import('../views/menu/financialmanagement/FinanceZ.vue') // 相关文件
                },
                {
                  path: "FinanceVoucher",//财务管理下的财务凭证
                  // name: "FinanceVoucher",
                  component: ()=> import('../views/menu/financialmanagement/FinanceVoucher.vue') //财务凭证
                },
              ]
            },
            {
              path: "/AaskAechive", // 任务档案
              name: "AaskAechive",
              component: ()=> import('../views/menu/archivesManagement/TaskAechive.vue')
            },
            {
              path: "/Achievements", // 成果档案
              name: "Achievements",
              redirect: '/Achievements/Paper',
              component: ()=> import('../views/menu/archivesManagement/Achievements.vue'),
              children: [
                {
                  path: "Paper", // 论文
                  name: "Paper",
                  component: ()=> import('../views/menu/archivesManagement/Paper.vue')
                },
                {
                  path: "Patent", // 专利
                  name: "Patent",
                  component: ()=> import('../views/menu/archivesManagement/Patent.vue')
                },
                {
                  path: "GuideExpert", // 指南专家共识
                  name: "GuideExpert",
                  component: ()=> import('../views/menu/archivesManagement/GuideExpert.vue')
                },
                {
                  path: "SummaryReport", // 分析总结报告
                  name: "SummaryReport",
                  component: ()=> import('../views/menu/archivesManagement/SummaryReport.vue')
                },
                {
                  path: "RawData", // 原始数据
                  name: "RawData",
                  component: ()=> import('../views/menu/archivesManagement/RawData.vue')
                },
                {
                  path: "OtherFile", // 原始数据
                  name: "OtherFile",
                  component: ()=> import('../views/menu/archivesManagement/OtherFile.vue')
                },
              ]
            },
            // {
            //   path: "/Paper", // 论文
            //   name: "Paper",
            //   component: ()=> import('../views/menu/archivesManagement/Paper.vue')
            // },
            // {
            //   path: "/Patent", // 专利
            //   name: "Patent",
            //   component: ()=> import('../views/menu/archivesManagement/Patent.vue')
            // },
            // {
            //   path: "/GuideExpert", // 指南专家共识
            //   name: "GuideExpert",
            //   component: ()=> import('../views/menu/archivesManagement/GuideExpert.vue')
            // },
            // {
            //   path: "/SummaryReport", // 分析总结报告
            //   name: "SummaryReport",
            //   component: ()=> import('../views/menu/archivesManagement/SummaryReport.vue')
            // },
            // {
            //   path: "/RawData", // 原始数据
            //   name: "RawData",
            //   component: ()=> import('../views/menu/archivesManagement/RawData.vue')
            // },
          ]
        },
        {
          path: "/System", //系统设置
          name: "System",
          redirect: "/Systemsettings",
          component: ()=> import('../views/menu/Systemsettings.vue'),
          children: [
            {
              path: "/Systemsettings", //系统设置目录
              name: "Systemsettings",
              component: () => import('../views/menu/systemsettings/Systemsettings.vue')
            }
          ]
        },
        // 管理员维护
        { // 导航页
          path: "/navber",
          name: "navber",
          redirect: '/jurisdiction',
          component: () => import('../views/adminMaintain/navbar.vue'),
          children: [
            //成员维护
            {
              path: "/member",
              name: "member",
              component: () => import('../views/adminMaintain/member.vue')
            },
            //权限设置
            {
              path: "/jurisdiction",
              name: "jurisdiction",
              component: ()=> import('../views/adminMaintain/jurisdiction.vue')
            },
            //采购组织维护
            {
              path: "/procure",
              name: "procure",
              component: ()=> import('../views/adminMaintain/procure.vue')
            },
           //医院维护
            {
                path: "/hosptail",
                name: "hosptail",
                component: ()=> import('../views/adminMaintain/addHosptail.vue')
              },
              {
                path: "/Accesslog",
                name: "Accesslog",
                component: ()=> import('../views/adminMaintain/Accesslog.vue')
              },
          ]
        },
      ]
    },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (window.sessionStorage.getItem('Token')) { // 如果已经登录的话
    next();
  } else {
    if (to.path == '/'||to.path == '/VerificationCode') { // 如果是登录页面的话，直接next()
      next();
    } else { // 否则 跳转到登录页面
      next({
        path: '/'
      });
    }
  }
});

export default router;
