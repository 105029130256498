import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import GuidanceChart from "@/components/GuidanceChart";
Vue.component("guidance-chart",GuidanceChart)
import ElementUI from 'element-ui';//引入所以的element组件库
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element_ui.scss'
Vue.use(ElementUI);
import ManagementList from "@/components/Management/ManagementList";
Vue.component("management-list",ManagementList)

import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push//解决路由重新点击报错问题
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
ElementUI.Dialog.props.closeOnClickModal.default = false//全局设置element-ui Dialog组件的close-on-click-modal属性为false
ElementUI.Table.props.highlightCurrentRow = { type: Boolean, default: true }
import "./styles/date_picker.scss";//DatePicker 日期选择器样式修改
Vue.config.productionTip = false;
import "../src/utils/dialogDrag"


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
