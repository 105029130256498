<template>
  <div>
    <div class="Tips" id="moveDiv" ref="moveDiv"
         @click="tipsClick"
         @mousedown="down"
         @touchstart="down"
         @mousemove="move"
         @touchmove.prevent="move"
         @mouseup="end"
         @touchend="end">
      <img src="../assets/image/tips/openTips.png" alt="">
    </div>
    <div v-if="dialogVisible" style="text-align: center" class="btn">
      <img :src="tips.url" alt="" class="img">
      <button @click="onClose" class="btn1">我知道了</button>
      <div class="close" @click="onClose">
        <i class="el-icon-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "GuidanceChart",
  data(){
    return{
      position: { x: 0, y: 0 }, //鼠标的横纵坐标
      pageX: "", //页面可视区域的宽
      pagey: "", //页面可视区域的高
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      flags: false,
      dialogVisible: true
    }
  },
  created() {
    if (localStorage.getItem(this.tips.name)) {
      this.dialogVisible = false
    }
  },
  props: {
    tips: {
      type:Object,
      defaullt: () => {}
    }
  },
  mounted(){
    this.pageX = document.body.offsetWidth;
    this.pagey = document.body.offsetHeight;
  },
  methods:{
    down() {
      this.flags = true; //是否开启拖拽
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.moveDiv.offsetLeft;
      this.dy = this.$refs.moveDiv.offsetTop;
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;

        this.yPum = this.dy + this.ny;
        if (this.dx + this.nx <= 0) {
          this.xPum = 0;
        } else if(this.dx + this.nx >= this.pageX -100){
          this.xPum = this.pageX - 100;
        } else {
          this.xPum = this.dx + this.nx;
        }
        if (this.dy + this.ny <= 0) {
          this.yPum = 0;
        } else if(this.dy + this.ny >= this.pagey -100){
          this.yPum = this.pagey - 100;
        } else {
          this.yPum = this.dy + this.ny;
        }
        this.$refs.moveDiv.style.left = this.xPum + "px";
        this.$refs.moveDiv.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
        document.addEventListener(
          "touchmove",
          function() {
            event.preventDefault();
          },
          false
        );
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    tipsClick() {
      this.dialogVisible = true
    },
    onClose() {
      this.dialogVisible = false
      this.$emit('tips-child', this.tips.name )
    },
  }
}
</script>

<style scoped lang="scss">
@import "../styles/mixin.scss";
.Tips{
  width: 100px;
  height: 100px;
  position:fixed;
  bottom: 4%;
  right: 2%;
  z-index: 9;
  img{
    width: 60px;
    height: 87px;
  }
}
.btn {
  text-align: center;
  position:absolute;left:0px;top:0px;
  background:rgba(0, 0, 0, 0.4);
  width:100%;  /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
  height:100%;
  filter:alpha(opacity=90);  /*设置透明度为90%*/
  opacity:0.9;  /*非IE浏览器下设置透明度为90%*/
  display:block;
  z-Index:999;
  .img {
    position:absolute;top: 0;left: 0;
    width: 100%;
    color: #fff;
    z-index: 9999;
  }
 .btn1 {
    position:absolute;bottom: 100px;left: 46%;
    width: 180px;
    border: 1px solid #409eff;
    background-color: #409eff;
    height: 50px;
    border-radius: 6px;
    font-size: 24px;
    color: #fff;
    cursor:pointer;
    z-index: 9999999;
  }
  .close {
    position:absolute;top: 2%;right: 2%;
    font-size: 24px;
    cursor:pointer;
    z-index: 9999999;
    //color: #fff;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 30px;
  }
}
</style>
