<template>
  <div id="app">
    <Loading v-if="LOADING"></Loading>
    <router-view></router-view>
  </div>
</template>
<script>
import Loading from '@/components/loading.vue';
import { mapState } from 'vuex';
export default {
  data(){
    return{
    }
  },
  components: {
		Loading
	},
	computed: {
		...mapState(['LOADING'])
	},
  mounted(){
  },
  methods:{
  }
}
</script>
<style lang="scss">

dialog::backdrop{
  background: rgba(200, 200, 200, 0.621);
}

.el-message {
  top: 10%!important
}
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  min-width: 500px;
  height: 100%;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
// .Tips{
//   width: 100px;
//   height: 100px;
//   background: brown;
//   position:fixed;
//   bottom: 100px;
//   right: 100px;
// }
  //   /deep/.el-table__body tr.current-row>td{
  //   background-color: #f19944 !important;
  // /* color: #f19944; */  /* 设置文字颜色，可以选择不设置 */
  //  }
</style>
